import AuthNotification from '@/components/AuthNotification'
import { Checkbox, TextInput } from '@/components/FormElement'
import AuthLayout from '@/components/Layouts/AuthLayout'
import { ButtonWithLoading, ContinueWith, SocialButtonGroup } from '@/components/buttons'
import { useAuth } from '@/hooks/Auth'
import { useLanguage } from '@/hooks/Contexts'
import { API_URL } from '@/utils/Consts'
import Link from 'next/link'
import { ReactElement, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'

const Login = (): ReactElement => {
  const { auth: authStr, errors: errStr } = useLanguage()

  const pathBeforeAuth = useRef('/user/dashboard')

  useEffect(() => {
    pathBeforeAuth.current = sessionStorage.getItem('pathBeforeAuth') ?? '/user/dashboard'
    sessionStorage.clear()
  }, [])

  const {
    doAuth,
    isLoading,
    errors: authError,
    getSocialLoginLink,
  } = useAuth({
    middleware: 'guest',
    redirectIfAuthenticated: pathBeforeAuth.current,
  })

  const formInstance = useForm({ mode: 'onBlur' })
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = formInstance

  const submit = data => {
    doAuth(API_URL.login, data)
  }

  return (
    <AuthLayout type={0}>
      {authError && <AuthNotification message={authError} className='mt-6' />}
      <form onSubmit={handleSubmit(submit)} className='mt-6 w-full lg:w-96'>
        <TextInput
          label={authStr.username_or_email}
          input={{
            'placeholder': authStr.username_or_email_placeholder,
            'disabled': isLoading,
            ...register('email', { required: authStr.username_or_email + errStr.required }),
            // @ts-ignore
            'data-testid': 'inp-login-email',
          }}
          required={true}
          showValidated={!!errors?.email}
          valid={!errors?.email}
          tipMessage={errors?.email?.message?.toString() ?? null}
        />

        <TextInput
          outerCls='mt-6'
          label={authStr.password}
          input={{
            'type': 'password',
            'autoComplete': 'password',
            'placeholder': authStr.password_placeholder,
            'disabled': isLoading,
            ...register('password', { required: authStr.password + errStr.required }),
            // @ts-ignore
            'data-testid': 'inp-login-pwd',
          }}
          required={true}
          showValidated={!!errors?.password}
          valid={!errors?.password}
          tipMessage={errors?.password?.message?.toString() ?? null}
          visibility
        />

        <div className='mt-6 flex w-full flex-row items-center'>
          <Checkbox
            text={authStr.remember}
            extraCls='text-sm'
            input={{ ...register('remember', { value: true }) }}
            // @ts-ignore
            span={{ 'data-testid': 'ckb-login-rememeberMe' }}
          />
          <div className='flex-1 cursor-pointer self-end text-right text-sm text-cyan-v2 hover:text-amber-v1' data-testid='btn-login-lostPwd'>
            <Link href='/forget-password'>{authStr.lost_pass}</Link>
          </div>
        </div>
        <ButtonWithLoading
          type='submit'
          className='btn-rd-amber btn-hover-amber btn-disabled-grey mx-auto mt-6 h-10 w-full max-w-sm'
          isLoading={isLoading}
          title={authStr.login_now}
          data-testid='btn-login-confirm'
        />

        <ContinueWith className='mx-auto mt-6 flex max-w-sm flex-row items-center' />
        <SocialButtonGroup socialLogin={getSocialLoginLink} className='mx-auto mt-6 max-w-sm' />
      </form>
    </AuthLayout>
  )
}

export default Login
