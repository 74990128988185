export default function AuthNotification(props: { message: any; className?: string }) {
  return (
    <>
      {props.message && (
        <div className={`font-medium text-error ${props.className || ''}`} data-testid='txt-error-incorrectPwd'>
          {props.message}
        </div>
      )}
    </>
  )
}
