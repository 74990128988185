import { useLanguage } from '@/hooks/Contexts'
import { svgs } from '@/utils/Images'
import { SupportedOAuthProviders } from '@/utils/Types'
import { FC, HTMLAttributes, useMemo } from 'react'
import { Divider } from '../FormElement'

export const ContinueWith: FC<HTMLAttributes<HTMLDivElement>> = props => {
  const { auth: authStr } = useLanguage()
  return (
    <div {...props}>
      <Divider className='flex-1 bg-neutral-300' />
      <div className='text-dark mx-4 text-center text-sm'>{authStr.continue_with}</div>
      <Divider className='flex-1 bg-neutral-300' />
    </div>
  )
}

export function SocialButtonGroup({
  socialLogin,
  className,
  ...rest
}: {
  // eslint-disable-next-line no-unused-vars
  socialLogin: (provider: SupportedOAuthProviders) => Promise<void>
} & HTMLAttributes<HTMLDivElement>) {
  const options = useMemo(
    () => [
      { svg: svgs.google, label: 'Google', click: () => socialLogin('google'), testid: 'btn-login-google' },
      { svg: svgs.facebook, label: 'Facebook', click: () => socialLogin('facebook'), testid: 'btn-login-facebook' },
    ],
    []
  )
  return (
    <div {...rest} className={`flex justify-center gap-x-4 ${className || ''}`}>
      {options.map((item, index) => (
        <button
          key={index}
          onClick={item.click}
          aria-label={item.label ?? 'Social Media button'}
          data-testid={item.testid}
          type='button'
          className='flex w-full text-cyan-v2 transition-colors duration-300 first:justify-end last:justify-start hover:text-amber-v1'
        >
          <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {item.svg}
          </svg>
        </button>
      ))}
    </div>
  )
}

export default SocialButtonGroup
