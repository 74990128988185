import { PropsWithChildren, ReactElement } from 'react'

/**
 * The Primary Button. Primarily used for CTAs.
 *
 * @param {object} props
 *   The props object.
 */
export function PrimaryButton(props: PropsWithChildren<{ onClick: any; extraCls: string; disabled: boolean }>): ReactElement {
  return (
    <button
      type='button'
      className={`${props.disabled ? 'btn-disabled ' : 'btn-primary '} ${props.extraCls || ''}`}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  )
}

export default PrimaryButton
